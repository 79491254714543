/* eslint-disable no-console */
import './app.css';

import { Box, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import Topbar from './components/topbar/Topbar';
import Attacktree from './pages/attacktree/Attacktree';
import Attackvectors from './pages/attackvectors/Attackvectors';
import Documentation from './pages/documentation/Documentation';
import Home from './pages/home/Home';
import References from './pages/references/References';
import Safeguards from './pages/safeguards/Safeguards';
import { theme } from './theme';

const defaultMainBackground = {
  backgroundImage:
    'linear-gradient(45deg, rgba(2, 0, 36, 0.85) 60%, rgba(9, 121, 104, 1) 75%, rgba(2, 0, 36, 0.85) 100%)',
  overflow: 'hidden',
};

const defaultContainerWidth = '1128px';

// const defaultContainerStyles = {
//   boxSizing: 'border-box',
//   marginX: 'auto',
//   maxWidth: '1128px',
//   minHeight: 'none',
//   paddingX: '2rem',
//   paddingY: '200px',
// };

// const attackTreeContainerStyles = {
//   boxSizing: 'border-box',
//   marginX: 'auto',
//   maxWidth: 'none',
//   minHeight: 'calc(100vh - 56px)',
//   padding: '2rem',
// };

const App = () => {
  const { pathname } = useLocation();
  const [topbarVariant, setTopbarVariant] = useState('light');
  const [mainBackground, setMainBackground] = useState(defaultMainBackground);
  const [containerMaxWidth, setContainerMaxWidth] = useState(
    defaultContainerWidth,
  );
  // const [containerStyles, setContainerStyles] = useState(
  //   defaultContainerStyles,
  // );

  useEffect(() => {
    if (pathname !== '/') {
      setTopbarVariant('dark');
      setMainBackground({
        backgroundColor: '#fff',
      });
    } else {
      setTopbarVariant('light');
      setMainBackground(defaultMainBackground);
    }

    if (pathname === '/attack-tree') {
      setContainerMaxWidth('none');
    } else {
      setContainerMaxWidth(defaultContainerWidth);
    }
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Topbar variant={topbarVariant} />
      <Box component="main" sx={{ ...mainBackground }}>
        <Box
          sx={{
            boxSizing: 'border-box',
            marginX: 'auto',
            maxWidth: containerMaxWidth,
            minHeight:
              pathname === '/attack-tree' ? 'calc(100vh - 56px)' : 'none',
            paddingX: pathname === '/attack-tree' ? '2rem' : '0',
            paddingY: pathname === '/attack-tree' ? '2rem' : '200px',
          }}
        >
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/attack-tree" element={<Attacktree />} />
            <Route exact path="/attack-vectors" element={<Attackvectors />} />
            <Route exact path="/safeguards" element={<Safeguards />} />
            <Route exact path="/references" element={<References />} />
            <Route exact path="/documentation" element={<Documentation />} />
          </Routes>
        </Box>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
