import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import { node, object } from 'prop-types';
import React, { useState } from 'react';

import referencestable from '../../data/references.json';
import safeguardstable from '../../data/safeguards.json';

const safeguardExistingTypes = [
  'Directive',
  'Preventive',
  'Detective',
  'Corrective',
];

const safeguardExistingStakeholdersRoles = [
  'Project Maintainer',
  'Administrator',
  'Downstream User',
];

function referencesListConstructor(row) {
  let chipsList = []; // List to hold the set of tags per-reference
  let referencesList = []; // List to hold the set of references per-safeguard

  // Collect the refs from the references table related to the specific safeguard
  let foundReferences = referencestable.filter(
    (element) =>
      element.safeguards && element.safeguards.some((x) => x.sgId === row.sgId),
  );

  for (let i = 0; i < foundReferences.length; i++) {
    if (foundReferences[i].tags.contents) {
      // Create the set of tags per reference
      foundReferences[i].tags.contents.forEach((e) => {
        if (e === 'attack') {
          chipsList.push(
            <Chip
              key="attack"
              label={e}
              size="small"
              color="error"
              variant="outlined"
            />,
          );
        } else if (e === 'peer-reviewed') {
          chipsList.push(
            <Chip
              key="peer-reviewed"
              label={e}
              size="small"
              color="secondary"
              variant="outlined"
            />,
          );
        } else if (e === 'standard') {
          chipsList.push(
            <Chip
              key="standard"
              label={e}
              size="small"
              color="default"
              variant="outlined"
            />,
          );
        }
      });
    }

    referencesList.push(
      <Box
        key={foundReferences[i].title}
        sx={{
          paddingBottom: '0.75rem',
        }}
      >
        <Typography variant="body2" component="div">
          <Link
            color="secondary"
            href={foundReferences[i].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {foundReferences[i].title}
          </Link>
          &nbsp;{chipsList}
        </Typography>
      </Box>,
    );
    chipsList = [];
  }

  return referencesList;
}

const Row = (props) => {
  const { row } = props;
  const rowInfo = row.info[0];
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row['sgId']}
        </TableCell>
        <TableCell align="left">{row['sgName']}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={1}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
        ></TableCell>
        <TableCell colSpan={3} sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                paddingY: '1rem',
              }}
            >
              <Typography variant="h4" mb="0.5rem">
                Description
              </Typography>

              <Box>
                <Typography variant="body1">
                  {parse(rowInfo['Description'])}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: '1px solid #D6D9DF66',
                paddingY: '1rem',
              }}
            >
              <Typography variant="h4" mb="0.5rem">
                References
              </Typography>
              <Box>{referencesListConstructor(row)}</Box>
            </Box>
            <Box
              sx={{
                borderTop: '1px solid #D6D9DF66',
                paddingY: '1rem',
              }}
            >
              <Typography variant="h4" mb="0.5rem">
                Control Type
              </Typography>
              <Box component="ul">
                {Object.keys(rowInfo).map(
                  (info) =>
                    safeguardExistingTypes.includes(info) &&
                    !!rowInfo[info] && (
                      <Typography key={info} component="li" variant="body1">
                        {info}
                      </Typography>
                    ),
                )}
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: '1px solid #D6D9DF66',
                paddingY: '1rem',
              }}
            >
              <Typography variant="h4" mb="0.5rem">
                Stakeholders Role
              </Typography>
              <Box component="ul">
                {Object.keys(rowInfo).map(
                  (info) =>
                    safeguardExistingStakeholdersRoles.includes(info) &&
                    !!rowInfo[info] && (
                      <Typography key={info} component="li" variant="body1">
                        <strong>{info}:</strong> {row.info[0][info]}
                      </Typography>
                    ),
                )}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          colSpan={1}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
        ></TableCell>
      </TableRow>
    </>
  );
};

Row.propTypes = {
  row: object.isRequired,
};

const TableContainerComponent = ({ children }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: '#D9D9D9',
    }}
  >
    {children}
  </Box>
);

TableContainerComponent.propTypes = {
  children: node.isRequired,
};

const Safeguards = () => {
  return (
    <Box>
      <Typography variant="h2" component="h1" mb="2rem">
        Safeguards List
      </Typography>

      <TableContainer component={TableContainerComponent}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell
                colSpan={3}
                align="left"
                style={{ fontWeight: 'bold' }}
              >
                Safeguard
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {safeguardstable.map((row) => (
              <Row key={row['sgName']} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Safeguards;
