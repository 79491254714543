import { ExpandLess, ExpandMore, Feed } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { SidebarDataDocumentation, sidebarItems } from './SidebarData';

export const Sidebar = ({ isOpen, setIsOpen }) => {
  const [listOpen, setListOpen] = useState(true);

  const handleClickList = () => {
    setListOpen((currListOpen) => !currListOpen);
  };

  const scrollToDiv = (e) => {
    document
      .getElementById(e.split('#')[1])
      .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Drawer
      anchor="left"
      component="nav"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        color: '#555',
      }}
      PaperProps={{
        sx: {
          paddingX: '2rem',
          paddingY: '4rem',
          width: '400px',
        },
      }}
      ModalProps={{
        onBackdropClick: () => setIsOpen(false),
      }}
    >
      <Box
        sx={{
          marginX: 'auto',
          width: '300px',
        }}
      >
        <Link
          href="https://www.endorlabs.com/"
          target="_blank"
          sx={{
            display: 'block',
            marginBottom: '2rem',
            marginLeft: '1rem',
          }}
        >
          <Box
            component="img"
            height="51px"
            src={`${process.env.PUBLIC_URL}/img/logo-dark.svg`}
            width="125px"
          />
        </Link>
        <List>
          {sidebarItems.map(({ icon, path, title }) => (
            <ListItem
              key={path}
              sx={{
                padding: 0,
              }}
            >
              <ListItemButton component={RouterLink} to={path}>
                <ListItemIcon sx={{ minWidth: '30px' }}>{icon}</ListItemIcon>
                <ListItemText
                  primary={title}
                  primaryTypographyProps={{
                    fontFamily: 'Space Grotesk, sans-serif',
                    fontSize: '14px',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}

          <ListItem
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton onClick={handleClickList}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <Feed fontSize="11px" />
              </ListItemIcon>
              <ListItemText
                primary="Documentation"
                primaryTypographyProps={{
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '14px',
                }}
              />
              {listOpen ? (
                <ExpandLess fontSize="11px" />
              ) : (
                <ExpandMore fontSize="11px" />
              )}
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{
              padding: 0,
            }}
          >
            <Collapse in={listOpen} timeout="auto" unmountOnExit>
              <List disablePadding>
                {SidebarDataDocumentation.map(({ icon, hash, path, title }) => (
                  <ListItem
                    key={hash}
                    sx={{
                      padding: 0,
                      paddingLeft: '1.5rem',
                    }}
                  >
                    <RouterLink
                      style={{ color: 'inherit', textDecoration: 'inherit' }}
                      to={{
                        pathname: path,
                        hash: hash,
                        state: { fromDashboard: true },
                      }}
                      onClick={() => scrollToDiv(hash)}
                    >
                      <ListItemButton>
                        <ListItemIcon sx={{ minWidth: '30px' }}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={title}
                          primaryTypographyProps={{
                            fontFamily: 'Space Grotesk, sans-serif',
                            fontSize: '14px',
                          }}
                        />
                      </ListItemButton>
                    </RouterLink>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};

export default Sidebar;
