import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        label: {
          fontFamily: 'Space Mono, monospaced',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero: 'h1',
          bodyHero: 'p',
        },
      },
      variants: [
        {
          props: { variant: 'hero' },
          style: {
            fontFamily: 'Space Mono, monospace',
            fontSize: '4rem',
            fontWeight: 600,
            lineHeight: '4.125rem',
          },
        },
        {
          props: { variant: 'bodyHero' },
          style: {
            color: '#fff',
            fontFamily: 'Space Grotesk, sans-serif',
            fontSize: '0.875rem',
            fontWeight: 300,
            lineHeight: '1.225rem',
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: '#00F078',
    },
    secondary: {
      main: '#005652',
    },
    text: {
      main: '#6F6F6F',
    },
    error: {
      main: '#FF4B4B',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Space Mono, monospace',
      fontSize: '4rem',
      fontWeight: 600,
      letterSpacing: '0.1rem',
      lineHeight: '4.125rem',
    },
    h2: {
      fontFamily: 'Space Mono, monospace',
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: '2.1rem',
    },
    h3: {
      fontFamily: 'Space Mono, monospace',
      fontSize: '1.313rem',
      fontWeight: 600,
      lineHeight: '1.838rem',
    },
    h4: {
      fontFamily: 'Space Mono, monospace',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.2rem',
    },
    h5: {
      fontFamily: 'Space Mono, monospace',
      fontSize: '0.938rem',
      fontWeight: 600,
      lineHeight: '1.219rem',
    },
    body1: {
      fontFamily: 'Space Grotesk, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    body2: {
      fontFamily: 'Space Grotesk, sans-serif',
      fontSize: '0.938rem',
      fontWeight: 400,
      lineHeight: '1.219rem',
    },
  },
});
