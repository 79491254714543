import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { node, object } from 'prop-types';
import React, { useMemo, useState } from 'react';

import references from '../../data/references.json';
import {
  generateAffectedPackagesList,
  generateEcosystemList,
  generateSafeguardsList,
  generateTags,
  generateVectorList,
} from './utils';

const referencesTableColumns = [
  {
    accessorKey: 'title',
    cell: ({ getValue, row }) => {
      return (
        <>
          <Box mb="0.5rem">{getValue()}</Box>
          <Box>
            <a
              href={row.original?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.original?.link.match(
                /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
              ) + '/...'}
            </a>
          </Box>
        </>
      );
    },
    enableSorting: true,
    header: () => 'Title',
    id: 'title',
    size: 200,
  },
  {
    accessorKey: 'year',
    cell: ({ row: { original } }) => original.tags.year,
    enableSorting: true,
    header: () => 'Year',
    id: 'year',
    size: 40,
    sortingFn: (rowA, rowB) => {
      const { year: rowAYear } = rowA.original?.tags || {};
      const { year: rowBYear } = rowB.original?.tags || {};

      if (rowBYear > rowAYear) return -1;
      if (rowAYear === rowBYear) return 0;
      if (rowAYear > rowBYear) return 1;
    },
  },
  {
    accessorKey: 'ecosystems',
    cell: ({ row: { original } }) =>
      original.tags?.ecosystems?.sort()?.join(', '),
    enableSorting: false,
    header: () => 'Ecosystems',
    id: 'ecosystems',
    sortingFn: (rowA, rowB) => {
      const { ecosystems: rowAEcosystems } = rowA.original?.tags || {};
      const { ecosystems: rowBEcosystems } = rowB.original?.tags || {};

      const rowAFirstEcosystem =
        rowAEcosystems && !!rowAEcosystems.length
          ? rowAEcosystems?.sort()[0]
          : undefined;
      const rowBFirstEcosystem =
        rowBEcosystems && !!rowBEcosystems.length
          ? rowBEcosystems?.sort()[0]
          : undefined;

      if (rowBFirstEcosystem > rowAFirstEcosystem) return -1;
      if (rowAFirstEcosystem === rowBFirstEcosystem) return 0;
      if (rowAFirstEcosystem > rowBFirstEcosystem) return 1;
    },
  },
  {
    accessorKey: 'vectors',
    cell: ({ getValue }) => generateVectorList(getValue()),
    header: () => 'Related Attack Vector(s)',
    id: 'vectors',
  },
  {
    accessorKey: 'safeguards',
    cell: ({ getValue }) => generateSafeguardsList(getValue()),
    header: () => 'Related Safeguard(s)',
    id: 'safeguards',
  },
  {
    accessorKey: 'tags',
    cell: ({ getValue }) => generateTags(getValue()),
    header: () => 'Tags',
    id: 'tags',
  },
  {
    accessorKey: 'tags',
    cell: ({ row }) => generateAffectedPackagesList(row.original?.tags),
    header: () => 'Affected Package(s)',
    id: 'packages',
  },
];

const Row = (props) => {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left">
          <div>{row.title}</div>
          <div>
            <a href={row.link} target="_blank" rel="noopener noreferrer">
              {row.link.match(
                /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
              ) + '/...'}
            </a>
          </div>
        </TableCell>
        <TableCell align="left">
          {row.tags && row.tags.year ? row.tags.year : ''}
        </TableCell>
        <TableCell align="left">{generateEcosystemList(row)}</TableCell>
        <TableCell align="left">{generateVectorList(row)}</TableCell>
        <TableCell align="left">{generateSafeguardsList(row)}</TableCell>
        <TableCell align="left">{generateTags(row)}</TableCell>
        <TableCell align="left">{generateAffectedPackagesList(row)}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  row: object.isRequired,
};

const TableContainerComponent = ({ children }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: '#D9D9D9',
      overflowX: 'auto',
    }}
  >
    {children}
  </Box>
);

TableContainerComponent.propTypes = {
  children: node.isRequired,
};

const References = () => {
  const [sorting, setSorting] = useState([]);

  const memoizedData = useMemo(() => references, []);

  const { getHeaderGroups, getRowModel, getState, setPageIndex, setPageSize } =
    useReactTable({
      data: memoizedData,
      state: {
        sorting,
      },
      columns: referencesTableColumns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSorting,
    });

  return (
    <Box>
      <Typography component="h1" mb="1rem" variant="h2">
        References
      </Typography>
      <Typography mb="2rem" variant="body1">
        All of the references below relate in one way or the other to software
        supply chain security, e.g. by describing real-world attacks or
        vulnerabilities, analyzing ecosystem weaknesses, presenting
        proof-of-concepts or suggesting safeguards. References are linked to
        attack vectors and safeguards where applicable, and tags like
        &quot;peer-reviewed&quot; or &quot;attack&quot; are used to categorize
        the content. Though the names of affected open-source projects and
        packages are provided in the last table column, supporting lookups, we
        do not strive for completeness. In this context, also refer to other
        data sets related to real-world attacks, e.g. the{' '}
        <a
          href="https://dasfreak.github.io/Backstabbers-Knife-Collection/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Backstabber&apos;s Knife Collection
        </a>{' '}
        or IQT Labs&apos;{' '}
        <a
          href="https://github.com/IQTLabs/software-supply-chain-compromises"
          target="_blank"
          rel="noopener noreferrer"
        >
          Supply Chain Compromises
        </a>
        .
      </Typography>

      <Box>
        <TablePagination
          component="div"
          page={getState().pagination.pageIndex}
          count={memoizedData.length}
          rowsPerPage={getState().pagination.pageSize}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          onPageChange={(_e, page) => setPageIndex(page)}
          onRowsPerPageChange={(event) =>
            setPageSize(Number(event.target.value))
          }
        />
      </Box>
      <TableContainer component={TableContainerComponent}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSorted = header.column.getIsSorted();

                  return (
                    <TableCell
                      key={header.id}
                      sx={{ minWidth: header.getSize() }}
                    >
                      {header.column.getCanSort() ? (
                        <TableSortLabel
                          active={!!isSorted}
                          direction={isSorted ? isSorted : undefined}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </TableSortLabel>
                      ) : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells()?.map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <TablePagination
          component="div"
          page={getState().pagination.pageIndex}
          count={memoizedData.length}
          rowsPerPage={getState().pagination.pageSize}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          onPageChange={(_e, page) => setPageIndex(page)}
          onRowsPerPageChange={(event) =>
            setPageSize(Number(event.target.value))
          }
        />
      </Box>
    </Box>
  );
};

export default References;
