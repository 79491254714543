import React from 'react';

import Taxonomy from '../../components/taxonomy/Taxonomy';
import attacktreedata from '../../data/taxonomy.json';

export const Attacktree = () => {
  return <Taxonomy data={attacktreedata} />;
};

export default Attacktree;
