/* eslint-disable react/prop-types */
/* eslint-disable no-console */

import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import Select, { components } from 'react-select';

import AttackVectors from '../../data/attackvectors.json';

const controlStyles = {
  background: '#f5f5f5',
  borderRadius: '0.5rem',
  color: '#555',
  padding: '0.5rem',
};

const ControlComponent = (props) => (
  <Box sx={controlStyles}>
    <Typography variant="body1" mb="0.5rem" textAlign="center">
      Attack Vectors
    </Typography>
    <components.Control {...props} />
  </Box>
);

const getAttackList = () =>
  AttackVectors.map((attack) => ({
    label: attack.avName,
    value: attack.avId,
  }));

class AttackSearchbar extends Component {
  attacklist = getAttackList();

  handleChange = (selectedOption) => {
    this.props.selectedAttack(selectedOption ? selectedOption.value : '');
  };

  render() {
    return (
      <Select
        isClearable
        isDisabled={this.props.disable}
        options={this.attacklist}
        styles={{
          container: (base) => ({
            ...base,
            marginBottom: '1rem',
          }),
          control: (base) => ({
            ...base,
            borderColor: '#FF4B4B',
            '&:hover': {
              borderColor: '#FF4B4B',
              boxShadow: 'none',
            },
          }),
        }}
        onChange={this.handleChange}
        components={{ Control: ControlComponent }}
        defaultValue={this.attacklist.filter(
          (element) => element.value === this.props.fromUrl,
        )}
      />
    );
  }
}

export default AttackSearchbar;
