import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import { node, object } from 'prop-types';
import React, { useState } from 'react';

import SGModal from '../../components/modal/Modal';
import attacktable from '../../data/attackvectors.json';
import referencestable from '../../data/references.json';
import safeguardstable from '../../data/safeguards.json';

const safeguardExistingTypes = [
  'Directive',
  'Preventive',
  'Detective',
  'Corrective',
];
const safeguardExistingStakeholdersRoles = {
  'Project Maintainer': 'Project Maintainer',
  Administrator: 'Administrator',
  'Downstream User': 'Consumer',
};

function referencesListConstructor(row) {
  let chipsList = [];
  let referencesList = [];
  let foundReferences = referencestable.filter(
    (element) =>
      element.vectors && element.vectors.some((x) => x.avId === row.avId),
  );

  for (let i = 0; i < foundReferences.length; i++) {
    if (foundReferences[i].tags.contents) {
      foundReferences[i].tags.contents.forEach((e) => {
        if (e === 'attack') {
          chipsList.push(
            <Chip
              key="attack"
              label={e}
              size="small"
              color="error"
              variant="outlined"
            />,
          );
        } else if (e === 'peer-reviewed') {
          chipsList.push(
            <Chip
              key="peer-reviewed"
              label={e}
              size="small"
              color="secondary"
              variant="outlined"
            />,
          );
        }
      });
    }

    referencesList.push(
      <Box
        key={foundReferences[i].title}
        sx={{
          paddingBottom: '0.75rem',
        }}
      >
        <Typography variant="body2" component="div">
          <Link
            color="secondary"
            href={foundReferences[i].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {foundReferences[i].title}
          </Link>
          &nbsp;{chipsList}
        </Typography>
      </Box>,
    );
    chipsList = [];
  }

  return referencesList;
}

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [safeguardModalProps, setSafeguardModalProps] = useState({});

  const buildSafeguardModal = (safeguardId) => {
    const safeguard = safeguardstable.find(
      (element) => element.sgId === safeguardId,
    );
    const safeguardInfo = safeguard.info[0];
    const safeguardTypes = Object.keys(safeguardInfo)
      .filter(
        (info) =>
          safeguardExistingTypes.includes(info) && !!safeguardInfo[info],
      )
      .sort();
    const safeguardStakeholdersRoles = Object.keys(safeguardInfo)
      .filter(
        (info) =>
          !!safeguardExistingStakeholdersRoles[info] && !!safeguardInfo[info],
      )
      .map((stakeholdersRole) => (
        <Typography
          key={safeguardExistingStakeholdersRoles[stakeholdersRole]}
          component="li"
          variant="body2"
        >
          <strong>
            {safeguardExistingStakeholdersRoles[stakeholdersRole]}:
          </strong>{' '}
          {safeguardInfo[stakeholdersRole]}
        </Typography>
      ));

    const safeguardStakeholdersRolesComponent = (
      <Box component="ul">{safeguardStakeholdersRoles}</Box>
    );

    setSafeguardModalProps({
      id: safeguard.sgId,
      name: safeguard.sgName,
      description: safeguardInfo['Description'],
      types: safeguardTypes.join(', '),
      stakeholdersRoles: safeguardStakeholdersRolesComponent,
    });
  };

  return (
    <>
      {isOpen && (
        <SGModal
          {...safeguardModalProps}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      <>
        <TableRow sx={{ '& > *': { borderBottom: 'none !important' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row['avId']}
          </TableCell>
          <TableCell align="left">{row['avName']}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={1}
            sx={{ paddingBottom: 0, paddingTop: 0 }}
          ></TableCell>
          <TableCell colSpan={3} sx={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  paddingY: '1rem',
                }}
              >
                <Typography variant="h4" mb="0.5rem">
                  Description
                </Typography>

                <Box>
                  {row.info.map((infoRow) => (
                    <Typography variant="body1" key={infoRow['Description']}>
                      {parse(infoRow['Description'])}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  borderTop: '1px solid #D6D9DF66',
                  paddingY: '1rem',
                }}
              >
                <Typography variant="h4" mb="0.5rem">
                  References
                </Typography>

                <Box>{referencesListConstructor(row)}</Box>
              </Box>
              {!!row.info[0]['Mapped Safeguard'].length && (
                <Box
                  sx={{
                    borderTop: '1px solid #D6D9DF66',
                    paddingY: '1rem',
                  }}
                >
                  <Typography variant="h4" mb="0.5rem">
                    Mapped Safeguards
                  </Typography>

                  <Box>
                    {row?.info[0]['Mapped Safeguard']?.map((sg) => (
                      <Button
                        key={sg.sgId}
                        variant="text"
                        onClick={() => {
                          buildSafeguardModal(sg.sgId);
                          setIsOpen(true);
                        }}
                        sx={{
                          color: 'text.main',
                          display: 'block',
                          fontFamily: 'Space Grotesk, sans-serif',
                          fontWeight: '400',
                          textTransform: 'none',
                        }}
                      >
                        [{sg.sgId}]{' '}
                        {
                          safeguardstable.filter((e) => e.sgId === sg.sgId)[0]
                            .sgName
                        }{' '}
                        <OpenInNew
                          fontSize="inherit"
                          sx={{ verticalAlign: 'sub' }}
                        />
                      </Button>
                    ))}
                  </Box>
                </Box>
              )}
            </Collapse>
          </TableCell>
          <TableCell
            colSpan={1}
            sx={{ paddingBottom: 0, paddingTop: 0 }}
          ></TableCell>
        </TableRow>
      </>
    </>
  );
};

Row.propTypes = {
  row: object.isRequired,
};

const TableContainerComponent = ({ children }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: '#D9D9D9',
    }}
  >
    {children}
  </Box>
);

TableContainerComponent.propTypes = {
  children: node.isRequired,
};

export const AttackVectors = () => {
  return (
    <Box>
      <Typography variant="h2" component="h1" mb="2rem">
        Attack Vectors List
      </Typography>

      <TableContainer component={TableContainerComponent}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell
                colSpan={3}
                align="left"
                style={{ fontWeight: 'bold' }}
              >
                Attack Vector
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attacktable.map((row) => (
              <Row key={row['avName']} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AttackVectors;
