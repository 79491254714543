/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import { Close } from '@mui/icons-material';
import { Box, Button, Modal, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React from 'react';

export const SGModal = ({
  description,
  id,
  isOpen,
  name,
  references,
  setIsOpen,
  stakeholdersRoles,
  types,
}) => {
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxSizing: 'border-box',
          margin: '10rem auto',
          maxHeight: '700px',
          maxWidth: '679px',
          overflow: 'auto',
          padding: '2rem',
        }}
      >
        <Box sx={{ marginBottom: '2rem', position: 'relative' }}>
          <Button
            onClick={() => setIsOpen(false)}
            sx={{ position: 'absolute', right: '-1rem', top: '-1rem' }}
          >
            <Close color="text" />
          </Button>
          <Typography
            variant="h4"
            sx={{ paddingX: '8rem', textAlign: 'center' }}
          >
            {id}: {name}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" mb="0.5rem">
            Description
          </Typography>
          <Typography variant="body2">{parse(description)}</Typography>

          {references && (
            <>
              <Typography variant="h5" mb="0.5rem" mt="1rem">
                References
              </Typography>
              <Box
                component="ul"
                sx={{ listStyle: 'none', margin: 0, padding: 0 }}
              >
                {references}
              </Box>
            </>
          )}

          <Typography variant="h5" mb="0.5rem" mt="1rem">
            Safeguard Type
          </Typography>
          <Typography variant="body2">{types}</Typography>

          <Typography variant="h5" mb="0.5rem" mt="1rem">
            Stakeholders Role
          </Typography>
          {stakeholdersRoles}
        </Box>
      </Box>
    </Modal>
  );
};

export default SGModal;
