import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';

export const Footer = () => (
  <Box
    component="footer"
    sx={{ backgroundColor: 'primary.main', height: '56px' }}
  >
    <Grid
      container
      sx={{
        alignItems: 'center',
        height: '100%',
        margin: 'auto',
        maxWidth: '1128px',
      }}
    >
      <Grid item>
        <Typography
          sx={{
            color: '#303030',
            fontFamily: 'Space Mono, monospaced',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.313',
          }}
        >
          This work builds on top of the{' '}
          <Link
            color="secondary"
            href="https://sap.github.io/risk-explorer-for-software-supply-chains/"
            target="_blank"
            rel="noopener noreferrer"
          >
            tool
          </Link>{' '}
          and{' '}
          <Link
            color="secondary"
            href="https://arxiv.org/abs/2204.04008"
            target="_blank"
            rel="noopener noreferrer"
          >
            taxonomy
          </Link>{' '}
          developed by SAP Security Research.
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default Footer;
