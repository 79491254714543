import { Box, Typography } from '@mui/material';
import { node, string } from 'prop-types';
import React from 'react';

export const HomeBox = ({ children, title }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'primary.main',
      borderRadius: '8px',
      padding: '3rem',
    }}
  >
    <Typography
      variant="h3"
      sx={{
        color: 'primary.main',
        textTransform: 'uppercase',
      }}
    >
      {title}
    </Typography>
    <Box mt="1.5rem">{children}</Box>
  </Box>
);

HomeBox.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
};
