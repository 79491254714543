import {
  AccountTree,
  Architecture,
  BugReport,
  Home,
  Newspaper,
  Schema,
  VerifiedUser,
} from '@mui/icons-material';
import React from 'react';

export const sidebarItems = [
  {
    title: 'Home',
    path: '/',
    icon: <Home fontSize="11px" />,
  },
  {
    title: 'Attack Tree',
    path: '/attack-tree',
    icon: <AccountTree fontSize="11px" />,
  },
  {
    title: 'Attack Vectors',
    path: '/attack-vectors',
    icon: <BugReport fontSize="11px" />,
  },
  {
    title: 'References',
    path: '/references',
    icon: <Newspaper fontSize="11px" />,
  },
  {
    title: 'Safeguards',
    path: '/safeguards',
    icon: <VerifiedUser fontSize="11px" />,
  },
];

export const SidebarDataDocumentation = [
  {
    title: 'OSS Supply Chain Model',
    path: '/documentation',
    hash: '#oss-supp-model',
    icon: <Architecture fontSize="11px" />,
  },
  {
    title: 'Attack Tree Fundamentals',
    path: '/documentation',
    hash: '#attacktree-fund',
    icon: <Schema fontSize="11px" />,
  },
  // {
  //   title: 'JSON Data Structure',
  //   path: '/documentation',
  //   hash: '#jsondata',
  //   icon: <Code fontSize="11px" />,
  // },
];
