/* eslint-disable react/prop-types */
/* eslint-disable no-console */

import { Box, Typography } from '@mui/material';
import React, { Component } from 'react';
import ReactSelect, { components } from 'react-select';

import safeguardtable from '../../data/safeguards.json';

const controlStyles = {
  background: '#f5f5f5',
  borderRadius: '0.5rem',
  color: '#555',
  padding: '0.5rem',
};

const ControlComponent = (props) => (
  <Box sx={controlStyles}>
    <Typography variant="body1" mb="0.5rem" textAlign="center">
      Safeguards
    </Typography>
    <components.Control {...props} />
  </Box>
);

function getSafeguardList() {
  var sgList = [];

  safeguardtable.forEach((x) =>
    sgList.push({
      label: x['sgName'],
      value: x['sgId'],
    }),
  );
  return sgList;
}

class SafeguardSearchbar extends Component {
  sglist = getSafeguardList();

  handleChange = (selectedOption) => {
    this.props.selectedSafeguard(selectedOption ? selectedOption.value : '');
  };

  render() {
    return (
      <ReactSelect
        isClearable
        isDisabled={this.props.disable}
        options={this.sglist}
        styles={{
          container: (base) => ({
            ...base,
            marginBottom: '1rem',
          }),
          control: (base) => ({
            ...base,
            borderColor: '#00F078',
            '&:hover': {
              borderColor: '#00F078',
              boxShadow: 'none',
            },
          }),
        }}
        onChange={this.handleChange}
        components={{ Control: ControlComponent }}
      />
    );
  }
}

export default SafeguardSearchbar;
