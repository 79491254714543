import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import attacktreeExample from '../../images/AttackTreeExample-modified.png';
import ossSupModel from '../../images/OSS-SuppChainModel-FMC-modified.png';

export const Documentation = () => (
  <Box>
    <Typography variant="h1" mb="1.5rem">
      Documentation
    </Typography>

    {/* Open-Source Software Supply Chain Model */}
    <Box mb="3rem">
      <Typography variant="h2" mb="1rem">
        Open-Source Software Supply Chain Model
      </Typography>

      <Typography variant="body1">
        This section describes a generic and high-level model of open-source
        software supply chains. It covers typical systems, stakeholders and
        interactions that are relevant in the context of developing, building
        and distributing open-source artifacts, and which are targeted by the
        taxonomy&apos;s attack vectors. Using the{' '}
        <Link
          color="#005652"
          href="http://www.fmc-modeling.org/"
          rel="noopener noreferrer"
          target="_blank"
        >
          FMC notation
        </Link>
        , the model looks as follows:
      </Typography>

      <Box
        alt="OSS Supply Chain Model"
        component="img"
        src={ossSupModel}
        sx={{
          marginBottom: '2.5',
          maxWidth: '100%',
        }}
      />

      <Typography variant="h3">Systems</Typography>

      <Typography variant="body1">
        The systems considered comprise versioning control systems, build
        systems and package repositories. They do not necessarily correspond to
        concrete physical or virtual systems providing the respective function,
        but should be understood as roles, that can be exercised by a single
        host or 3rd party service.
      </Typography>

      <Box component="ul">
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <strong>Version control systems (VCS)</strong> host the source code
            of open-source projects, together with related metadata and
            configuration files. They track and manage all the changes to the
            source code thoughout the project lifetime. Plain VCSs (like{' '}
            <Link
              color="#005652"
              href="https://git-scm.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Git
            </Link>
            ) do not require its users to authenticate, but complementary tools
            offer additional functionalities. For example, they may provide
            issue trackers or improved security controls, like authentication,
            management of more fine-grained permissions or review workflows.
          </Typography>
        </Box>

        <Box component="li" mb="0.5rem">
          <strong>Build systems</strong> generate binary artifacts (like an
          executable or a compressed archive) starting from the project&apos;s
          codebase. CI/CD pipelines consist of a series of steps that have to be
          performed to deliver a new version of software. The common steps of a
          CI/CD pipeline are:
          <ul>
            <li>
              Build - the application is compiled, generally using
              build-automation tools like Make, Ant, Gradle, or xMake. The build
              process commonly involves dependency managers (like PIP or NPM) to
              determine and automatically download the required dependencies for
              the build to succeed.
            </li>
            <li>
              Test - the application is tested, generally in an automated
              fashion (e.g, using tools like JUnit)
            </li>
            <li>
              Release - the application is pushed into the associated repository
            </li>
            <li>Deploy - the application is deployed to production</li>
          </ul>
          CI/CD pipelines are automated through CI/CD tools, like Jenkins or
          Travis CI. The generated files and artifacts are then distributed to
          downstream users for easy consumption.
        </Box>

        <Box component="li" mb="0.5rem">
          <strong>Distribution platforms</strong> make pre-built OSS artifacts
          accessible to downstream users, for example through package managers
          or via manual download. As &quot;distribution platform&quot; we do not
          only consider public package repositories (like{' '}
          <Link
            color="#005652"
            href="https://pypi.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PyPI
          </Link>{' '}
          or{' '}
          <Link
            color="#005652"
            href="https://search.maven.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Maven Central
          </Link>
          ), but also internal/external mirrors, content delivery networks (CDN)
          or proxies.
        </Box>

        <Box component="li" mb="0.5rem">
          <strong>Workstations of OSS Maintainers and Administrators</strong>{' '}
          are also considered in the attack scenario, even though they are not
          explicitly depicted, because project maintainers and administrators
          use them to for privileged access to sensitive resources (like the
          project&apos;s codebase or the build system&apos;s web interface).
        </Box>
      </Box>

      <Typography variant="h3">Stakeholders</Typography>

      <Typography variant="body1">
        Stakeholders in the open-source supply chain model shall be understood
        as roles, multiple of which can be assumed by a given individual or
        group. Open-source project members, for instance, commonly assume the
        role of project maintainers (with privileged access to project
        resources) and downstream users (consuming upstream artifacts).
      </Typography>

      <Box component="ul">
        <Box component="li">
          <strong>Contributors</strong> contribute code to an Open-Source
          project, typically with limited permissions to project resources. They
          commonly submit their code in form of merge requests (or pull
          requests), so to let the project maintainers to perform reviews prior
          to being integrated into the codebase.
        </Box>

        <Box component="li">
          <strong>OSS Project Maintainers</strong> have privileged access to
          project resources. For this reason, they typically review and decide
          whether to integrate or not the contributors&apos; merge requests.
          Project maintainers configure and trigger build jobs, and deploy
          pre-built artifacts on package repositories. It must be noted that the
          real identities of both contributors and maintainers are not
          necessarily known.
        </Box>

        <Box component="li">
          <strong>System and Service Administrators</strong> configure, maintain
          and operate the aforementioned systems or services.
        </Box>

        <Box component="li">
          <strong>Downstream Users</strong> are the consumers of Open-Source
          project artifacts, either directly cloning the source code from the
          VCS or by downloading pre-built packages from distribution platforms.
          The latter is typically automated through package managers (like pip
          or npm), which also collect and obtain all the required dependencies
          (both direct and indirect).
        </Box>
      </Box>
    </Box>

    {/* Attack Trees 101 */}
    <Box mb="3rem">
      <Typography variant="h2">Attack Trees 101</Typography>

      <Typography variant="body1">
        <Link
          color="#005652"
          href="https://www.schneier.com/academic/archives/1999/12/attack_trees.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Attack trees
        </Link>{' '}
        are a formal representation of attacker goals and techniques. They
        support organizations and especially defenders to understand current
        exposure to threat and to identify the possible countermeasures to be
        used to protect the assets.
      </Typography>

      <Typography variant="body1">
        At the <b>root</b> of an attack tree the attacker&apos;s top-level goal
        is represented. Such goal is iteratively refined by its children into
        sub-goals. Depending on the degree of the refinement, nodes may
        correspond to more or less actionable tasks.
      </Typography>

      <Typography variant="body1">
        Nodes in an attack tree can be &apos;AND nodes&apos; or &apos;OR
        nodes&apos;. In the frist case, they represent different steps in
        achieving the parent&apos;s goal, while in the second case they
        represent alternatives. In our specific case, since we used the
        semantics of attack trees to outline a taxonomy, all the nodes have to
        be considered of &apos;OR&apos; type.
      </Typography>

      <Box
        alt="Attack tree example"
        component="img"
        src={attacktreeExample}
        sx={{
          maxWidth: '100%',
        }}
      />
    </Box>

    {/* JSON Data Structure */}
    {/* <Box mb="3rem">
      <Typography variant="h2">JSON Data Structure</Typography>

      <Typography variant="body1">
        To facilitate the improvement, update and extension of the data
        underlying the visualization, the dataset is split into four{' '}
        <Link
          href="https://www.json.org/json-en.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          JSON
        </Link>{' '}
        files:
      </Typography>

      <Box component="ul">
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`attackvectors.json`}</pre>{' '}
            contains a flat list of all attack vectors, each one having an
            identifier, a name and description as well as other properties
          </Typography>
        </Box>
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`taxonomy.json`}</pre>{' '}
            creates the tree structure by referencing and nesting all the attack
            vectors
          </Typography>
        </Box>
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`references.json`}</pre>{' '}
            contains a flat list of both scientific and grey literature
            references that deal with the identified attack vectors, thus,
            supporting their existence in our taxonomy
          </Typography>
        </Box>
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`safeguards.json`}</pre>{' '}
            contains a flat list of safeguards
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1">
        Let&apos;s suppose, for example, that a new attack for a given vector
        has been observed. This can be reflected in the dataset just by
        modifying{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`references.json`}</pre>
        .<br></br>
        <br></br>
        The addition of an entirely new attack vector, on the other side,
        requires the modification of the three files{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`attackvectors.json`}</pre>
        ,{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`taxonomy.json`}</pre>{' '}
        and{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`references.json`}</pre>
        .
      </Typography>

      <Typography variant="h3">Attack Vectors</Typography>

      <Typography variant="body1">
        The file{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`attackvectors.json`}</pre>{' '}
        contains a flat list of all attack vectors, each one having a unique
        identifier and name. Furthermore, each vector is characterized by a
        description, its impact, a list of related scientific references (if
        any), and a list of safeguards (if any).
        <br></br>
        An example excerpt of the file is the following:
      </Typography>

      <Box
        component="pre"
        sx={{
          fontFamily: 'Space Mono, monospaced',
          fontWeight: 'bold',
        }}
      >
        {`
  [
    {
      "avId": "AV-000",
      "avName": "Conduct Open-Source Supply Chain Attack",
      "info": [{
          "Description": "The attack tree focuses on open-source based software development ...",
          "Impact": "Conduct a Software Supply Chain attack",
          "Mapped Safeguard": [{
              "sgId": "SG-001"
          }, {
              "sgId": "SG-002"
          }, {
              "sgId": "SG-003"
          },
              ...
          ]
      }]
    }, {
        "avId": "AV-100",
        "avName": "Develop and Advertise Distinct Malicious Package from Scratch",
        "info": [{
            "Description": "This attack vector covers the creation of a new, seemingly legitimate ...",
            "Impact": "Inject Malicious Code ...",
            "Mapped Safeguard": []
        }]
    }, {
        "avId": "AV-200",
        "avName": "Create Name Confusion with Legitimate Package",
        "info": [{
            "Description": "The general idea behind name confusion is that ...",
            "Impact": "Inject Malicious Code ...",
            "Mapped Safeguard": [{
                "sgId": "SG-007"
            }, {
                "sgId": "SG-011"
            }, {
                "sgId": "SG-012"
            },
                ...
            ]
        }]
    },
    . . .
  ]
`}
      </Box>

      <Typography variant="body1">
        As we can observe, the list of attack vectors is a JSON array and each
        JSON element contains the following information:
      </Typography>

      <Box component="ul">
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`avId`}</pre>
            , that is a string describing the identifier associated to the
            specific attack vector
          </Typography>
        </Box>
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`avName`}</pre>
            , that is a string describing the name of the specific attack vector
          </Typography>
        </Box>
        <Box component="li" mb="0.5rem">
          <Typography variant="body1">
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`info`}</pre>{' '}
            , that is an array containing a single JSON object characterized by
            the following fields:
          </Typography>
          <Box component="ul">
            <Box component="li" mb="0.5rem">
              <Typography variant="body1">
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Description`}</pre>
                , that is a string describing the meaning and purpose of a
                specific attack vector{' '}
              </Typography>
            </Box>
            <Box component="li" mb="0.5rem">
              <Typography variant="body1">
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Impact`}</pre>
                , that is a string describing the impact related to a successful
                attack{' '}
              </Typography>
            </Box>
            <Box component="li" mb="0.5rem">
              <Typography variant="body1">
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Mapped Safeguard`}</pre>
                , that is an array containing the list of safeguards related to
                the specific attack vector. Each safeguard is indexed using the
                related identifier{' '}
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`sgId`}</pre>{' '}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography variant="h3">Taxonomy</Typography>

      <Typography variant="body1">
        The file{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`taxonomy.json`}</pre>{' '}
        arranges the attack vectors in the hierarchical structure of the attack
        tree.
        <br></br>
        The following excerpt, for instance, makes the two attack vectors{' '}
        <em>
          Develop and Advertise Distinct Malicious Package from Scratch
        </em>{' '}
        and <em>Create Name Confusion with Legitimate Package</em> child nodes
        of <em>Conduct Open-Source Supply Chain Attack</em>:
      </Typography>

      <Box
        component="pre"
        sx={{
          fontFamily: 'Space Mono, monospaced',
          fontWeight: 'bold',
        }}
      >
        {`
{
  "avName": "Conduct Open-Source Supply Chain Attack",
  "avId": "AV-000",
  "children": [{
      "avName": "Develop and Advertise Distinct Malicious Package from Scratch",
      "avId": "AV-100"
      },
      {
      "avName": "Create Name Confusion with Legitimate Package",
      "avId": "AV-200",
      "children": [{
          ...
      ]},
      ...
  ]
}
`}
      </Box>

      <Typography variant="body1">
        As we can observe, the taxonomy is a JSON object and each JSON element
        (node of the tree) contains the following information:
        <ul>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`avName`}</pre>
            , that is a string describing the name of the specific attack vector
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`avId`}</pre>
            , that is a string describing the identifier associated to the
            specific attack vector
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`children`}</pre>{' '}
            (optional), that is an array containing the list of children nodes
            of an attack vector. In this case we follow the semantics of attack
            trees{' '}
          </li>
        </ul>
      </Typography>

      <Typography variant="h3">References</Typography>

      <Typography variant="body1">
        The file{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`references.json`}</pre>{' '}
        contains a flat list of both scientific and grey literature references
        supporting the presence of the attack vectors in the taxonomy. The list
        may include, for example, peer-reviewed papers, master/PhD thesis,
        standards, technical reports, a blog post talking about a real-world
        attack, a vulnerability disclosure affecting any of the involved
        systems, or a whitepaper describing a proof-of-concept that (if
        exploited) could have led to a supply chain attack.
      </Typography>

      <Typography variant="body1">
        The template to document each reference entry is the following:
      </Typography>

      <Box
        component="pre"
        sx={{
          fontFamily: 'Space Mono, monospaced',
          fontWeight: 'bold',
        }}
      >
        {`
{
  "title": "",
  "link": "",
  "vectors": [
      {
          "avId": "",
          "avName": "",
          "scopeAvId": "",
          "scopeAvName": ""
      }
  ],
  "safeguards": [
      {
          "sgId": "",
          "sgName": ""
      }
  ],
  "tags": {
      "ecosystems": ["Java", "JavaScript", ...],
      "packages": ["event-stream", "..."],
      "contents": ["peer-reviewed", "attack", "vulnerability", ...],
      "year": null
  }
}
`}
      </Box>

      <Typography variant="body1">
        The list of references is a JSON array and each JSON element contains
        the following information:
        <ul>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`title`}</pre>
            , that is a string describing the title of the peer-reviewed paper,
            the real-world attack described in a news page and so on
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`link`}</pre>
            , that is a string containing the URL of the reference
          </li>
        </ul>
        <br />
        <br />
        The{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`vectors`}</pre>{' '}
        array contains the list of attack vectors to which the reference is
        mapped. You may notice that in the tree there are attack vectors that
        appear in different <b>scopes</b>. In fact, the compromission of the
        maintainer system may happen both in the case of the VCS and in the one
        of the build system, for example. For this reason, some references may
        require the specification of the scope of the related attack vector.
        Thus the fields available in the{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`vectors`}</pre>{' '}
        array are:
        <ul>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`avId`}</pre>{' '}
            , that is a string describing the identifier of the attack vector
            that was at the root cause of the described supply chain attack{' '}
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`avName`}</pre>
            , that is a string describing the name of the specific attack vector
            that was at the root cause of the described supply chain attack{' '}
          </li>

          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`scopeAvId`}</pre>{' '}
            (optional), that is a string describing the identifier of the attack
            vector describing the scope
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`scopeAvName`}</pre>{' '}
            (optional), that is a string describing the name of the specific
            attack vector describing the scope{' '}
          </li>
        </ul>
        <br />
        <br />
        The{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`safeguards`}</pre>{' '}
        array contains the list of safeguards to which the reference is mapped,
        and the information required in this case are the following:
        <ul>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`sgId`}</pre>
            , that is a string describing the identifier associated to the
            specific safeguard
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`sgName`}</pre>
            , that is a string describing the name of the specific safeguard
          </li>
        </ul>
        The{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`tags`}</pre>{' '}
        dictionary contains additional information related to the reference:
        <ul>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`ecosystems`}</pre>
            , that is an array of strings describing the involved ecosystems
            (e.g., Python, JavaScript, Java)
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`packages`}</pre>
            , that is an array of strings, describing the name of affected
            packages
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`contents`}</pre>
            , that is an array describing the type of the resource. The possible
            values are:
            <ul>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`peer-reviewed`}</pre>
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`thesis`}</pre>
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`vulnerability`}</pre>
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`attack`}</pre>
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`proof-of-concept`}</pre>
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`presentation`}</pre>
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`standard`}</pre>
              </li>
            </ul>
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`year`}</pre>
            , that is an integer describing the year of publishing of the
            resource
          </li>
        </ul>
      </Typography>

      <Typography variant="h3">Safeguards</Typography>

      <Typography variant="body1">
        The file{' '}
        <pre
          style={{
            display: 'inline-block',
            fontFamily: 'Space Mono, monospaced',
          }}
        >{`safeguards.json`}</pre>{' '}
        contains the list of safeguards that (partially or fully) mitigate
        different attack vectors. Each safeguard has a unique identifier and
        name and is characterized by a description, a list of related scientific
        references (if any), its type (directive, preventive, detective or
        corrective) and a description how the different stakeholders implement,
        apply or use it.
      </Typography>

      <Typography variant="body1">
        An example excerpt of the file is the following:
      </Typography>

      <Box
        component="pre"
        sx={{
          fontFamily: 'Space Mono, monospaced',
          fontWeight: 'bold',
        }}
      >
        {`
[{
      "sgId": "SG-001",
      "sgName": "Software Bill of Materials (SBOM)",
      "info": [{
          "Description": "A Software Bill of Material (SBOM) is a ...",
          "Directive": false,
          "Preventive": true,
          "Detective": true,
          "Corrective": false,
          "Project Maintainer": "By maintaining a detailed SBOM, the Project Maintainer ...",
          "Administrator": "The Administrator will be involved by ...",
          "Downstream User": "Similarly to the code signing and signature verification, ..."
      }]
  },
  {
      "sgId": "SG-002",
      "sgName": "Patch Management",
      "info": [{
          "Description": "By performing regular software updates on ...",
          "Directive": false,
          "Preventive": true,
          "Detective": false,
          "Corrective": true,
          "Project Maintainer": "Project Maintainer could enstablish ... ",
          "Administrator": "Package Repositories could enstablish ...",
          "Downstream User": ""
      }]
  },
  ...
]
`}
      </Box>

      <Typography variant="body1">
        As we can observe, the list of attack vectors is a JSON array and each
        JSON element contains the following information:
        <ul>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`sgId`}</pre>
            , that is a string describing the identifier associated to the
            specific safeguard
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`sgName`}</pre>
            , that is a string describing the name of the specific safeguard
          </li>
          <li>
            <pre
              style={{
                display: 'inline-block',
                fontFamily: 'Space Mono, monospaced',
              }}
            >{`info`}</pre>{' '}
            , that is an array containing a single JSON object characterized by
            the following fields:
            <ul>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Description`}</pre>
                , that is a string describing the meaning and purpose of a
                specific safeguard
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Directive`}</pre>
                ,{' '}
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Preventive`}</pre>
                ,
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Detective`}</pre>
                , and{' '}
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Corrective`}</pre>{' '}
                are boolean fields that specify the type of safeguard{' '}
              </li>
              <li>
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Project Maintainer`}</pre>
                ,{' '}
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Administrator`}</pre>
                , and{' '}
                <pre
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Space Mono, monospaced',
                  }}
                >{`Open-Source Consumer`}</pre>{' '}
                are string fields describing the involvement of the specific
                stakeholders that is required to make the countermeasure
                effective{' '}
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
    </Box> */}
  </Box>
);

export default Documentation;
