import { Close, Menu } from '@mui/icons-material';
import { Box, Button, Link } from '@mui/material';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from '../sidebar/Sidebar';

const menuIconsDefatulProps = {
  fontSize: 'large',
  sx: {
    color: '#fff',
  },
};

const menuButtonsDefaultProps = {
  sx: {
    marginRight: '4rem',
    minWidth: 'auto',
    padding: 0,
  },
  variant: 'text',
};

export const Topbar = ({ variant }) => {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const menuIconsProps =
    variant === 'dark'
      ? { ...menuIconsDefatulProps, sx: { color: '#333333' } }
      : { ...menuIconsDefatulProps };

  // const topbarPadding = pathname === '/attack-tree' ? '' : '';

  return (
    <>
      <Box
        sx={{
          background: 'transparent',
          left: 0,
          paddingBottom: pathname === '/attack-tree' ? '0' : '4rem',
          paddingTop: '4rem',
          paddingX: pathname === '/attack-tree' ? '4rem' : '',
          position: 'absolute',
          right: pathname === '/attack-tree' ? 'unset' : 0,
          top: 0,
          zIndex: 9,
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            margin: 'auto',
            maxWidth:
              pathname === '/attack-tree' ? 'unset' : 'calc(1128px + 192px)',
            width: '100%',
          }}
        >
          {sidebarOpen ? (
            <Button
              {...menuButtonsDefaultProps}
              onClick={() => setSidebarOpen(false)}
            >
              <Close {...menuIconsProps} />
            </Button>
          ) : (
            <Button
              {...menuButtonsDefaultProps}
              onClick={() => setSidebarOpen(true)}
            >
              <Menu {...menuIconsProps} />
            </Button>
          )}

          <Link href="https://www.endorlabs.com/" target="_blank">
            <Box
              component="img"
              height="51px"
              src={`${process.env.PUBLIC_URL}/img/logo-${variant}.svg`}
              mt="4px"
              width="125px"
            />
          </Link>
        </Box>
      </Box>
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
    </>
  );
};

Topbar.propTypes = {
  variant: string.isRequired,
};

export default Topbar;
