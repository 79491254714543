import { Box, Chip } from '@mui/material';
import React from 'react';

import references from '../../data/references.json';

export const getAvailableEcosystems = () => {
  const allEcosystems = references
    .filter((ref) => !!ref.tags?.ecosystems)
    .reduce((acc, ref) => [...acc, ...ref.tags.ecosystems], []);

  const uniqueEcosystems = new Set(allEcosystems);

  return [...uniqueEcosystems].sort();
};

export const generateVectorList = (vectors) => {
  // Creates the list of attack vectors related to the reference
  if (vectors) {
    return (
      <Box
        component="ul"
        sx={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {vectors.map((vector, i) => {
          if (vector.scopeAvId) {
            return (
              <Box
                key={`vector_${i}`}
                component="li"
                sx={{ marginBottom: '0.5rem' }}
              >
                [{vector.avId}] {vector.avName} in the scope of [
                {vector.scopeAvId}] {vector.scopeAvName}
              </Box>
            );
          } else {
            return (
              <Box
                key={`vector_${i}`}
                component="li"
                sx={{ marginBottom: '0.5rem' }}
              >
                [{vector.avId}] {vector.avName}
              </Box>
            );
          }
        })}
      </Box>
    );
  }
};

export const generateSafeguardsList = (safeguards) => {
  // Creates the list of safeguards related to the reference
  if (safeguards) {
    return (
      <Box
        component="ul"
        sx={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {safeguards.map((safeguard, i) => (
          <Box
            key={`safeguard_${i}`}
            component="li"
            sx={{ marginBottom: '0.5rem' }}
          >
            [{safeguard.sgId}] {safeguard.sgName}
          </Box>
        ))}
      </Box>
    );
  }
};

export const generateEcosystemList = (row) => {
  return row?.tags?.ecosystems?.sort()?.join(', ');
};

export const createTagChip = (label, index) => {
  if (label === 'attack') {
    return (
      <Chip
        key={`attack_${index}`}
        label={label}
        size="small"
        color="error"
        sx={{
          marginBottom: '0.5rem',
        }}
      />
    );
  } else if (label === 'peer-reviewed') {
    return (
      <Chip
        key={`peer-reviewed_${index}`}
        label={label}
        size="small"
        color="primary"
        sx={{
          marginBottom: '0.5rem',
        }}
      />
    );
  } else {
    return (
      <Chip
        key={`default_${index}`}
        label={label}
        size="small"
        color="default"
        sx={{
          marginBottom: '0.5rem',
        }}
      />
    );
  }
};

export const generateTags = (tags) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }}
  >
    {tags?.contents?.map((tagLabel, i) => createTagChip(tagLabel, i))}
  </Box>
);

export const generateAffectedPackagesList = (tags) => {
  if (tags && !!tags?.packages?.length) {
    return (
      <Box
        component="ul"
        sx={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {tags?.packages.map((affectedPackage, i) => (
          <Box
            key={`affected-package_${i}`}
            component="li"
            sx={{ marginBottom: '0.5rem' }}
          >
            {affectedPackage}
          </Box>
        ))}
      </Box>
    );
  }
};
