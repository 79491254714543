import { Shortcut } from '@mui/icons-material';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { HomeBox } from '../../components/HomeBox/HomeBox';
import { ReactComponent as AttacktreeImage } from '../../images/attackgraph.svg';

const Home = () => {
  return (
    <>
      <Box
        sx={{
          marginBottom: '200px',
        }}
      >
        <Grid container>
          <Grid item lg={6} md={12}>
            <Typography color="#fff" marginBottom="1.5rem" variant="h1">
              Risk Explorer for Software Supply Chains
            </Typography>

            <Typography variant="bodyHero" mb="0.5rem">
              This page presents a taxonomy of known attacks and techniques to
              inject malicious code into open-source software projects.
            </Typography>

            <Typography variant="bodyHero" mb="0.5rem">
              A so-called attack tree is used to organize those techniques
              hierarchically, starting from the abstract, top-level goal down to
              alternative and more concrete attack techniques.
            </Typography>

            <Typography variant="bodyHero" mb="0.5rem">
              This information has been compiled on the basis of numerous
              real-world incidents, i.e. actual attacks and vulnerabilities, as
              well as plausible proof-of-concepts and scientific literature.
            </Typography>

            <Typography variant="bodyHero">
              The page also documents safeguards to fully or partially mitigate
              the different attack techniques, thereby referencing existing
              standards and frameworks.
            </Typography>

            <Box mt="2rem">
              <Button
                component={RouterLink}
                size="large"
                to="/attack-tree"
                variant="contained"
                sx={{
                  borderRadius: '1rem',
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: '1.25rem',
                  marginRight: '2rem',
                  padding: '1rem',
                  textTransform: 'none',
                }}
              >
                Explore the attack tree
              </Button>

              <Button
                href="https://arxiv.org/abs/2204.04008"
                rel="noopener noreferrer"
                size="large"
                target="_blank"
                variant="contained"
                sx={{
                  borderRadius: '1rem',
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '1rem',
                  fontWeight: 500,
                  lineHeight: '1.25rem',
                  padding: '1rem',
                  textTransform: 'none',
                }}
              >
                Read the paper
              </Button>
            </Box>
          </Grid>

          <Grid item xs={6} sx={{ position: 'relative' }}>
            <Box
              sx={{
                bottom: 0,
                height: '125vh',
                left: '-10%',
                position: 'absolute',
                right: 0,
                top: '-40%',
                width: '100vw',
                '.node text': {
                  fill: '#fff',
                },
              }}
            >
              <AttacktreeImage
                style={{
                  bottom: 0,
                  left: 0,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid
          container
          columnSpacing={6}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          zIndex={1}
          position="relative"
        >
          {/* <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'stretch',
            }}
          >
            <HomeBox title="Why">
              <Typography variant="bodyHero" mb="1rem">
                We found that existing works on open-source supply chain
                security lack a comprehensive, comprehensible, and general
                description of how attackers inject malicious code into
                open-source projects, linked to real-world incidents and
                independent of specific programming languages, ecosystems,
                technologies and stakeholders.
              </Typography>

              <Typography variant="bodyHero">
                We believe a taxonomy classifying such attacks is of value for
                both academia and industry. Serving as a common reference and
                clarifying terminology, it could support several activities,
                e.g. awareness-raising, safeguard development, pentest scoping
                or threat modeling.
              </Typography>
            </HomeBox>
          </Grid> */}

          <Grid
            item
            xs={12}
            // md={4}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'stretch',
            }}
          >
            <HomeBox title="How to use">
              <Typography variant="bodyHero" mb="1rem">
                Explore the taxonomy with help of the visualization tool:
                Single-click on a node to expand or collapse it, and to show
                associated information like its description, references,
                associated examples and related countermeasures.
              </Typography>
              <Link
                component={RouterLink}
                to="/attack-tree"
                sx={{
                  display: 'block',
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '1rem',
                  lineHeight: '1.25rem',
                  marginBottom: '1rem',
                }}
              >
                Explore the Attack Tree
                <Shortcut
                  sx={{ marginLeft: '0.5rem', verticalAlign: 'bottom' }}
                />
              </Link>
              <Link
                component={RouterLink}
                to="//documentation#oss-supp-model"
                sx={{
                  display: 'block',
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '1rem',
                  lineHeight: '1.25rem',
                }}
              >
                Read about the open-source supply chain model
                <Shortcut
                  sx={{ marginLeft: '0.5rem', verticalAlign: 'bottom' }}
                />
              </Link>

              <Typography variant="bodyHero">
                (stakeholders, systems and interactions)
              </Typography>
            </HomeBox>
          </Grid>

          {/* <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'stretch',
            }}
          >
            <HomeBox title="Contribute">
              <Typography variant="bodyHero" mb="1rem">
                The data model underlying the visualization comprises attack
                vectors, safeguards and references, all associated to one
                another.
              </Typography>
              <Link
                component={RouterLink}
                to="/documentation#jsondata"
                sx={{
                  display: 'inline-block',
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '1rem',
                  lineHeight: '1.25rem',
                  marginBottom: '1rem',
                }}
              >
                Read about the JSON files and their structure
                <Shortcut
                  sx={{ marginLeft: '0.5rem', verticalAlign: 'bottom' }}
                />
              </Link>
              <Link
                href="https://github.com/SAP/risk-explorer-for-software-supply-chains"
                sx={{
                  display: 'inline-block',
                  fontFamily: 'Space Grotesk, sans-serif',
                  fontSize: '1rem',
                  lineHeight: '1.25rem',
                }}
              >
                Create pull requests to add real-life examples or other
                improvements
                <Shortcut
                  sx={{ marginLeft: '0.5rem', verticalAlign: 'bottom' }}
                />
              </Link>
            </HomeBox>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Home;
